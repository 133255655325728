import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { useEffect } from 'react';
import styled from 'styled-components';
import { Analytics } from 'lib/analytics';

import { LoginForm } from 'page-components/auth/login-form';
import { GoToButton } from 'components/link/go-to-button';
import { Brand } from 'components/images/brand';
import { ContentCard } from 'design-system/components/content-card';
import { userCookieExist } from 'lib/auth';
import { useRouter } from 'next/router';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 30rem;
  margin: 10% auto 0;
`;

const AuthHelpContainer = styled.div`
  color: white;
  text-align: center;
`;

/* istanbul ignore next */
export function getServerSideProps(
  context: GetServerSidePropsContext
): GetServerSidePropsResult<{}> {
  if (userCookieExist(context.req)) {
    return {
      redirect: {
        statusCode: 302,
        destination: '/',
      },
    };
  }

  return { props: {} };
}

function signUpButtonPath(params: ReturnType<typeof useRouter>['query']) {
  const query: { forward_url?: string | string[] } = {};
  const forwardUrl = params.forward_url;

  if (forwardUrl) {
    query.forward_url = forwardUrl;
  }

  return { pathname: '/signup', query };
}

export default function LoginPage() {
  const { query } = useRouter();
  useEffect(() => {
    Analytics.trackPageView('Login page');
  }, []);

  return (
    <Container>
      <ContentCard>
        <Brand />
        <LoginForm />
      </ContentCard>

      <AuthHelpContainer>
        <p>Don’t have an account?</p>
        <GoToButton href={signUpButtonPath(query)} label="Sign Up" />
      </AuthHelpContainer>
    </Container>
  );
}
