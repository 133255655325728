/**
 * ContentCard is part of the Content* components that are the base layout for
 * the raised cards within the design system.
 *
 * View the Design System storybook stories to see visual examples.
 *
 * Example usages:
 *
 * ```
 * <VStack>
 *   <ContentCard>
 *     <ContentCardHeading><Icon /> Details</ContentCardHeading>
 *     <ContentCardCopy>
 *        A single paragraph of text.
 *     </ContentCardCopy>
 *     <ContentCardCopy>
 *        If you want more content, use multiple ContentCardCopy elements.
 *     </ContentCardCopy>
 *   </ContentCard>
 *   <ContentCard>
 *     <ContentCardHeading><Icon /> Details</ContentCardHeading>
 *     <ContentCardCopy>
 *        A single paragraph of text.
 *     </ContentCardCopy>
 *     <ContentCardCopy>
 *        If you want more content, use multiple ContentCardCopy elements.
 *     </ContentCardCopy>
 *   </ContentCard>
 *   <ContentCard>
 *     <ContentCardHeading>Details</ContentCardHeading>
 *     <ContentCardTable
 *       items={[
 *         ['Name', 'Clocktower Animal Hospital'],
 *         ['ID', 'G-000123469'],
 *         ['Phone', '+1 317 8675309'],
 *         ['Email', 'frontoffice@clocktoweranimalhospital.com'],
 *       ]}
 *     />
 *   </ContentCard>
 *   <ContentCard>
 *     <ContentCardHeading>List</ContentCardHeading>
 *     <ContentCardList<Item>
 *       renderHeader={() => (
 *         <tr>
 *           <th>Name</th>
 *           <th>Phone</th>
 *           <th>Email</th>
 *         </tr>
 *       )}
 *       renderItem={({ name, phone, email, photo }: Item) => (
 *         <tr key={name}>
 *           <td>
 *             <img src={photo.url} alt={`Profile for ${name}`} />
 *             {name}
 *           </td>
 *           <td>{phone}</td>
 *           <td>{email}</td>
 *         </tr>
 *       )}
 *       items={[
 *         {
 *           name: 'Abbey Martin',
 *           phone: '+16315531484',
 *           email: 'abbeyjmartin2001@gmail.com',
 *           photo: { url: 'https://picsum.photos/200' },
 *         },
 *         {
 *           name: 'Helga Chen',
 *           phone: '+12099962256',
 *           email: 'hchen_catsrule@aol.com',
 *           photo: { url: 'https://picsum.photos/256' },
 *         },
 *       ]}
 *     />
 *   </ContentCard>
 * </VStack>
 * ```
 **/
import { ComponentProps } from 'react';
import styled from 'styled-components';
import { VStack } from './stack';
import * as mq from '../theme/media-queries';
import * as colors from '../theme/colors';

export const ContentCard = styled(VStack).attrs<ComponentProps<typeof VStack>>(
  (props) => ({
    $spacing: props.$spacing ?? '1rem',
  })
)`
  background-color: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 15px;

  ${mq.md} {
    padding: 20px;
  }
`;

export const ContentCardTitle = styled.h1<{ $variant?: 'ALT' }>`
  color: ${({ $variant }) =>
    $variant === 'ALT' ? colors.secondary : 'inherit'};
  font-weight: 700;
  font-size: ${24 / 16}em;
  line-height: 1.333;
`;

export const ContentCardHeading = styled.h2`
  font-weight: 600;
  font-size: ${14 / 16}em;
  line-height: 1.5;
`;

export const ContentCardCopy = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 1.33;
  margin: 0;
`;

/**
 * <ContentCardTable items={Array<[label, value]>} /> definition-list style
 * table view.
 */
const Table = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  line-height: 1rem;
  gap: 1.5em;
`;
const TableKey = styled.div`
  color: rgb(114, 114, 114);
  font-weight: 700;
`;

const TableValue = styled.div`
  color: rgb(86, 86, 94);
  font-weight: 400;
  overflow-wrap: break-word;
  word-wrap: anywhere;
`;

export function ContentCardTable({
  className,
  items,
}: {
  // to enable styled(ContentCardTable) use cases;
  className?: string;
  items: Array<[key: string, value: JSX.Element | string]>;
}) {
  return (
    <Table className={className}>
      {items.map(([key, value]) => (
        <div key={key}>
          <TableKey>{key}</TableKey>
          <TableValue>{value}</TableValue>
        </div>
      ))}
    </Table>
  );
}

/**
 * <ContentCardList items={items} renderItem={item => <tr><td>JSON.string(item)</td></tr>} />
 * design system foundation.
 *
 * This is the foundation for all <ContentCardList />s in the application. It currently
 * assumes that all lists represent structured data. Within this assumption,
 * consumers are expected to use standard table elements and have the styling
 * automatically provided for them.
 *
 * View the `design-system/list-view` story in storybook to see the current
 * state.
 *
 *
 * Future efforts include:
 * * Adopting additional use cases that will require more layout configuration
 * * Keyboard accessibility through `downshift`
 */
const TableList = styled.table`
  border-collapse: separate;
  width: 100%;
`;
const borderTop = `border-top: solid 0 transparent;`;
const borderBottom = `border-bottom: solid 1px rgb(238, 238, 242);`;

const Thead = styled.thead`
  th {
    ${borderTop}
    ${borderBottom}
    color: rgb(114, 114, 144);
    font-weight: bold;
    padding: 1rem 0.5rem;
    text-align: left;
  }
`;

const Tbody = styled.tbody`
  td:first-child {
    svg,
    img {
      height: 64px;
      width: 64px;
      border-radius: 0.5rem;
      vertical-align: middle;
      margin-right: 1rem;
    }

    font-size: 1.5rem;
    font-weight: bold;
    color: ${colors.primary};
  }
  td {
    ${borderTop}
    ${borderBottom}
    color: rgb(45, 22, 62);
    padding: 1rem 0.5rem;
    vertical-align: middle;
    text-align: center;
    text-align: left;
  }

  ${mq.below_md} {
    td:first-child {
      svg,
      img {
        display: none;
      }
    }
    td:first-child,
    td {
      font-size: 0.875rem;
    }
  }
`;

interface Props<T> {
  items: ReadonlyArray<T>;
  renderItem(item: T): JSX.Element;
  renderHeader?(): JSX.Element;
}
export function ContentCardList<T>({
  items,
  renderItem,
  renderHeader,
}: Props<T>) {
  return (
    <TableList>
      {renderHeader ? <Thead>{renderHeader()}</Thead> : null}
      <Tbody>{items.map(renderItem)}</Tbody>
    </TableList>
  );
}
