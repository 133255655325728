/**
 * The form components are the recommended way to build forms at Pawlytics.
 * These require working in a <Formik /> environment and will automatically
 * provide a number of expected behaviors including:
 * * Validation and error message displaying
 * * onChange and onSubmit handling
 * * Accessible DOM structure and htmlFor/id connections
 * * TypeScript support
 * * <ErrorFocus /> to automatically focus on the first error’d field
 */
import { ComponentProps, InputHTMLAttributes, useEffect } from 'react';
import { Field as FormikField, ErrorMessage, useField } from 'formik';
import ReactSelect, { MenuPlacement, Props as SelectProps } from 'react-select';
import CreateableSelect from 'react-select/creatable';
import AsyncCreateableSelect from 'react-select/async-creatable';
import useMedia from 'react-use/lib/useMedia';
import styled from 'styled-components';
import { primary, positive } from '../theme/colors';
import { only_sm } from '../theme/media-queries';
const danger = '#ff5b5b';

export const Label = styled.label`
  color: ${primary};
  font-size: 0.975rem;
  display: flex;
  flex-direction: column;
  gap: 0.4em;
`;

export const Input = styled.input<{ $error?: string | void }>`
  border: 1px solid transparent;
  border-radius: 3px;
  background-color: rgb(238, 238, 242);
  padding: 0.5em 1em;

  border-color: ${({ $error }) => ($error ? danger : 'transparent')};
  height: 3em;

  -webkit-appearance: none;
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    border-color: ${({ $error }) => ($error ? danger : primary)};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

// HTMLSelect is used explicitly for situations where we don’t want to take on
// the overhead of Formik + React-select
export const HTMLSelect = styled(Input).attrs(() => ({ as: 'select' }))``;
export const Textarea = styled(Input).attrs(() => ({ as: 'textarea' }))`
  min-height: 7em;
`;

export const ErrorText = styled.div`
  color: ${danger};
  font-size: 0.875rem;
`;

/**
 * The <Field /> component is the recommended way to build forms at Pawlytics.
 * These require working in a <Formik /> environment and will automatically
 * provide a number of behaviors including:
 * * Validation and error message displaying
 * * onChange and onSubmit handling
 * * Accessible DOM structure and htmlFor/id connections
 * * TypeScript support
 *
 * Example:
 *    <Formik initialValues={{ test_field: '' }} onSubmit={() => {}}>
 *      {() => (
 *        <Field
 *          name="test_field"
 *          id="test_field"
 *          label="Test Input"
 *          as={Input}
 *        />
 *      )}
 *    </Formik>
 *
 * TODO:
 * * Add disabled styling
 */
interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  id: string;
  label: string | JSX.Element;
  as: ComponentProps<typeof FormikField>['as'];
  $error?: boolean;
}
export function Field({
  name,
  id,
  label,
  as,
  ...props
}: Props & ComponentProps<typeof FormikField>): JSX.Element {
  const labelId = `labelFor-${id}`;
  return (
    <Label htmlFor={id}>
      {
        // Checkboxes are special cased and have the albel passed through
        Checkbox !== as &&
          (typeof label === 'string' ? (
            <span id={labelId}>{label}</span>
          ) : (
            label
          ))
      }
      <FormikField
        id={id}
        as={as}
        name={name}
        aria-labelledby={labelId}
        label={as === Checkbox ? label : undefined}
        {...props}
      />
      <ErrorMessage component={ErrorText} name={name} />
    </Label>
  );
}

const CheckboxGroupLabel = styled.div`
  color: ${primary};
  font-size: 0.975rem;
  margin-bottom: 0.5em;
`;
const CheckboxGroupContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5em;
  label {
    display: flex;
    gap: 0.5em;
  }

  span {
    color: ${positive};
    font-size: 0.9rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export function Checkbox({
  label,
  ...props
}: { label: string | JSX.Element } & InputHTMLAttributes<HTMLInputElement>) {
  return (
    <CheckboxGroupContainer
      as="label"
      style={{ flexDirection: 'row', alignItems: 'center' }}
    >
      <input type="checkbox" {...props} />
      <span id={`labelFor-${props.id}`}>{label}</span>
    </CheckboxGroupContainer>
  );
}
/**
 * Render a grouped set of checkboxes together. Automatically manages the array
 * of values for you.
 *
 * Example:
 *    <Formik initialValues={{ group: ['value2'] }} onSubmit={() => {}}>
 *      {() => (
 *        <CheckboxGroup
 *          name="group"
 *          id="group"
 *          label="Test Group"
 *          options={[
 *            ['value1', 'Label 1'],
 *            ['value2', 'Label 2'],
 *            ['value3', 'Label 3'],
 *          ]}
 *        />
 *      )}
 *    </Formik>
 *
 */
export function CheckboxGroup({
  name,
  id,
  options,
  label,
  ...props
}: Omit<Props, 'as'> & {
  options: ReadonlyArray<[string, string]>;
}) {
  const labelFor = `labelFor_${id}`;
  return (
    <div role="group">
      <CheckboxGroupLabel id={labelFor}>{label}</CheckboxGroupLabel>
      <CheckboxGroupContainer role="group" aria-labelledby={labelFor}>
        {options.map(([value, label]) => {
          return (
            <label key={value}>
              <FormikField
                as={'input'}
                type="checkbox"
                name={name}
                {...props}
                value={value}
              />
              <span>{label}</span>
            </label>
          );
        })}
      </CheckboxGroupContainer>
      <ErrorMessage component={ErrorText} name={name} />
    </div>
  );
}

/**
 * Render a grouped set of checkboxes together. Automatically manages the array
 * of values for you.
 *
 * Example:
 *    <Formik initialValues={{ group: ['value2'] }} onSubmit={() => {}}>
 *      {() => (
 *        <CheckboxGroup
 *          name="group"
 *          id="group"
 *          label="Test Group"
 *          options={[
 *            ['value1', 'Label 1'],
 *            ['value2', 'Label 2'],
 *            ['value3', 'Label 3'],
 *          ]}
 *        />
 *      )}
 *    </Formik>
 *
 */
const NoopComponent = () => null;
const selectComponents = {
  ClearIndicator: NoopComponent,
  DropdownIndicator: NoopComponent,
  IndicatorSeparator: NoopComponent,
};
const selectStyles = {
  // 5 is the same level as the modal and navbar, so this lets the last select
  // in the DOM to pop one over the portal.
  menuPortal:
    /* istanbul ignore next */
    (base: any) => ({ ...base, zIndex: 5 }),
  control: (base: any, { isMulti, isDisabled }: any) => ({
    ...base,
    borderWidth: 1,
    borderType: 'solid',
    borderColor: 'transparent', // ${({ $error }) => ($error ? danger : 'transparent')};
    borderRightColor: '#fff',
    borderRadius: '3px',
    padding: '0.5em 1em',
    minHeight: '3em',
    height: isMulti ? 'inherit' : '3em',
    backgroundColor: isDisabled ? 'rgb(218, 218, 220)' : 'rgb(238, 238, 242)',
    ':hover,:focus': {
      borderColor: isDisabled ? '' : primary, // ${({ $error }) => ($error ? danger : primary)};
    },
  }),
  valueContainer: (base: object) => ({ ...base, padding: 0 }),
  multiValue: (base: object) => ({ ...base, background: 'transparent' }),
  multiValueLabel: (base: object) => ({
    ...base,
    paddingLeft: 0,
    paddingRight: 0,
  }),
};
interface Options {
  label: string;
  value: string;
}
export function __BaseSelect<Option>({
  as,
  menuPlacement,
  ...props
}: SelectProps<Option> & {
  as?: typeof CreateableSelect;
  menuPlacement?: MenuPlacement | undefined;
}) {
  // This is to support <Select as={Creatable} />
  const AsSelect = as || ReactSelect;
  return (
    <AsSelect
      styles={selectStyles}
      components={selectComponents}
      menuPlacement={menuPlacement || 'auto'}
      menuPortalTarget={
        // in production we want the portal target to be the body to break out
        // of modal containers, however in tests we need this in the local jsdom
        // Additionally, in SSR environments, document is not defined, and therefore
        // document.body will throw a TypeError
        // istanbul ignore next
        process.env.JEST_WORKER_ID === undefined &&
        typeof document !== 'undefined'
          ? document.body
          : null
      }
      {...props}
    />
  );
}
export function Select<Option>({
  isCreateable,
  menuPlacement,
  ...props
}: SelectProps<Option> & {
  // When changing some Select values we want to trigger additional side
  // effects, but we need to do this outside of the react-select and formik
  // worlds. This function is to enable that outcome while acknowledging we
  // desire a more react-y solution
  __HACK__onChange?: (option: Option) => void;
  isCreateable?: boolean | 'async';
  as?: typeof CreateableSelect;
  menuPlacement?: MenuPlacement | undefined;
}) {
  // @ts-expect-error
  const [_field, _, { setValue }] = useField(props);
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <__BaseSelect<Option>
      as={
        isCreateable === true
          ? CreateableSelect
          : isCreateable === 'async'
            ? AsyncCreateableSelect
            : undefined
      }
      {...props}
      menuPlacement={menuPlacement}
      onChange={(option) => {
        setValue(option);
        if (props.__HACK__onChange) {
          props.__HACK__onChange(option as any);
        }
      }}
    />
  );
}

const SelectStyledMobile = styled.select`
  border: 0 solid transparent;
  border-radius: '3px';
  background-color: rgb(238, 238, 242);
  padding: 0.5em 1em;
  width: 100%;
  min-height: 3em;
  height: 3em;
  &:hover,
  &:focus {
    border-color: ${primary};
    background-color: rgb(238, 238, 242);
  }
`;

type MobileProps = {
  label: string;
  options: Options[];
  ariaLabel: string;
  id: string;
  name: string;
  selected: Options;
  // When changing some Select values we want to trigger additional side
  // effects, but we need to do this outside of formik. This function is to enable
  // that outcome while acknowledging we desire a more react-y solution
  __HACK__onChange?: () => void;
};
const use_media_small = only_sm.replace('@media ', '');
export function SelectWithMobileFallback(props: MobileProps) {
  const isMobile = useMedia(use_media_small);

  const { label, options, id, name, selected } = props;
  const [field, _umm, { setValue }] = useField(name);
  return isMobile ? (
    <div>
      <Label>{label}</Label>
      <SelectStyledMobile
        {...field}
        id={id}
        value={selected.value}
        onChange={(e) => {
          const option = options.find((item) => item.value === e.target.value);
          setValue(option);
          // istanbul ignore next
          if (props.__HACK__onChange) {
            props.__HACK__onChange();
          }
        }}
      >
        {options.map((item) => {
          return (
            <option value={item.value} key={item.value} id={item.value}>
              {item.label}
            </option>
          );
        })}
      </SelectStyledMobile>
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-pascal-case
    <__BaseSelect
      {...props}
      onChange={(option) => {
        setValue(option);
        // istanbul ignore next
        if (props.__HACK__onChange) {
          props.__HACK__onChange();
        }
      }}
    />
  );
}

/**
 * The <ErrorFocus /> component should be added to every Form instance to
 * automatically focus on an error’d form input when validation fails.
 *
 * Example:
 *    <Formik
 *      validationSchema={yup.object().shape({ test_field: yup.string().min(1) })}
 *      initialValues={{ test_field: '' }}
 *      onSubmit={() => {}}
 *    >
 *      {() => (
 *        <ErrorFocus />
 *        <Field
 *          name="test_field"
 *          id="test_field"
 *          label="Test Input"
 *          as={Input}
 *        />
 *      )}
 *    </Formik>
 *
 */
export function ErrorFocus({ isSubmitting, isValidating, errors }: any) {
  useEffect(() => {
    // I tried for a solid 30+ minutes getting this to reflect a browser after
    // updating to React 18 and could not. This behavior will require manual
    // testing, but is also very rarely used in practice
    // istanbul ignore next
    for (const errorKey in errors) {
      if (isSubmitting && !isValidating) {
        const selector = `[id="${errorKey}"]`;
        const element = document.querySelector(selector) as HTMLInputElement;
        // this if (element) is for type safety, we are not concerned about the
        // else case in testing
        // istanbul ignore else
        if (element) {
          element.focus();
        }
        break;
      }
    }
  }, [isSubmitting, isValidating, errors]);

  return null;
}
