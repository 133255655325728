// Breakpoints stolen from https://tailwindcss.com/docs/responsive-design

export const only_sm = '@media (max-width: 480px)';
export const below_md = '@media (max-width: 767px)';
export const below_lg = '@media (max-width: 1023px)';
export const below_xl = '@media (max-width: 1279px)';
export const sm = '@media (min-width: 480px)';
export const md = '@media (min-width: 768px)';
export const lg = '@media (min-width: 1024px)';
// unused
// export const xl = '@media (min-width: 1280px)';
// export const xxl = '@media (min-width: 1536px)';
