import styled from 'styled-components';

export const Brand = styled.img.attrs({
  src: '/pawlytics-logo.png',
  alt: 'Pawlytics Logo',
  height: 44,
  width: 155,
})`
  display: inline-block;
  height: 44px;
  width: 155px;
`;
