import { ComponentProps } from 'react';
import Link, { LinkProps } from 'next/link';
import { Button, Variant, Intent } from 'design-system/components/button';

type Props = ComponentProps<typeof Button> & {
  href: LinkProps['href'];
  label: string | JSX.Element;
};

export function GoToButton({ href, label }: Props) {
  return (
    <Link href={href} passHref legacyBehavior>
      <Button as="a" $variant={Variant.Outline} $intent={Intent.Secondary}>
        {label}
      </Button>
    </Link>
  );
}
