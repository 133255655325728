import * as yup from 'yup';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useApolloClient, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { Formik, Form, FormikHelpers, ErrorMessage } from 'formik';

import { Anchor } from 'design-system/components/anchor';
import { Button } from 'design-system/components/button';
import { ErrorText, Field, Input } from 'design-system/components/form';
import { HStack, VStack } from 'design-system/components/stack';

import { PersonFragment } from 'gql/fragments';
import { queryCurrentUser } from 'gql/queries';
import { setSentryUser } from 'helpers/sentry';
import { login } from 'lib/auth';
import { Analytics } from 'lib/analytics';
import { PersonOrganizationPermission, User } from 'types/person';

export const createUpdateUserMutation = gql`
  mutation ($input: String) {
    create_update_user_for_auth(invite_token: $input) {
      id
      profile {
        ...PersonFragment
      }
    }
  }
  ${PersonFragment}
`;

const schema = yup.object().shape({
  email: yup
    .string()
    .required('You must provide a valid email address.')
    .matches(
      //eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      'You must provide a valid email address'
    ),
  password: yup.string().required('You must provide a password'),
});
type Values = {
  API_ERROR: string;
  email: string;
  password: string;
};

// Logging a user in has a few components to it:
// 1. We use Auth0 as an actual identity validator. So users get authorized by auth0
//
// 2. Once they are logged in we have to make sure we have a user for them in our system. We do that by the following logic:
//
// 2.a. First we query for the user with the `queryCurrentUser` query
//      If they exist we then redirect them to the home page (or use the forward url if its set)
//
// 2.b. If the user was authed by Auth0, but we don't have a user for them, we then have to create one.
//      We do this via the `createUpdateUserMutation`
//
export function LoginForm() {
  const [createUpdateUser] = useMutation<{ create_update_user_for_auth: User }>(
    createUpdateUserMutation
  );
  const router = useRouter();
  const client = useApolloClient();

  const onSubmit = useCallback(
    async function onSubmit(values: Values, actions: FormikHelpers<Values>) {
      // try to login
      try {
        await login({ username: values.email, password: values.password });
        const forwardUrl = (router.query.forward_url as string) || '/';

        // fetch existing user from GraphQL, if for some reason it is doesn't exist create a new one
        // redirect to index as user
        try {
          const res = await client.query<{
            user_current: User;
            user_permissions: PersonOrganizationPermission[];
          }>({ query: queryCurrentUser, fetchPolicy: 'network-only' });

          Analytics.trackUserLoggedIn(
            res.data.user_current,
            res.data.user_permissions
          );
          setSentryUser(values.email);
          window.Median?.identify(values.email);

          router.push(forwardUrl);
        } catch (e) {
          try {
            const res = await createUpdateUser();

            if (!!res.data?.create_update_user_for_auth.id) {
              router.push(forwardUrl);
            }
          } catch {
            actions.setFieldError(
              'API_ERROR',
              'Something went wrong. Please try logging in again.'
            );
          }
        }

        // `await login()` failure
      } catch (error: any) {
        actions.setFieldError(
          'API_ERROR',
          error.description || error.name || error.error
        );
      }
    },
    [router, createUpdateUser, client]
  );

  return (
    <Formik
      initialValues={{
        email: (router.query.email as string) || '',
        password: '',
        API_ERROR: '',
      }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <VStack as={Form}>
          <ErrorMessage name="API_ERROR" component={ErrorText} />
          <Field
            label="Email"
            name="email"
            id="email"
            as={Input}
            autoFocus
            type="email"
            placeholder="example@email.com"
            autoComplete="username"
          />

          <Field
            label="Password"
            type="password"
            name="password"
            id="password"
            as={Input}
            placeholder="password"
            autoComplete="current-password"
            data-median="exclude"
          />

          <HStack $justify-content="flex-end" $align-items="center">
            <Anchor
              as={Link}
              href="/reset-password"
              style={{ fontSize: '0.8rem' }}
            >
              Forgot Password?
            </Anchor>

            <Button
              disabled={!formik.dirty || formik.isSubmitting}
              type="submit"
            >
              Log in
            </Button>
          </HStack>
        </VStack>
      )}
    </Formik>
  );
}
