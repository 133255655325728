import { formatDate } from 'helpers/common';
import { Organization } from 'types/organization';
import { User, PersonOrganizationPermission } from 'types/person';
import { PetStatus } from 'page-components/pet_/_constants';
// TODO: move this typedof to the graphql generation
import { PetEventIntakeResponse } from 'page-components/pets/components/create-modal';

const noop = (..._args: any[]) => {};
/**
 * We stopped using Segment in 2019, but never ripped out our analytics
 * instrumentation.
 */
global.analytics = {
  identify: noop,
  page: noop,
  track: noop,
  alias: noop,
};

export const Analytics = {
  identify(
    userId: string,
    options: { company: { name: string; company_id: string } }
  ) {
    global.analytics.identify(userId, options);
  },

  trackPageView(pageTitle: string) {
    global.analytics.page(pageTitle);
  },

  trackNewOrganization(userId: string, organization: Organization) {
    const attributes = {
      name: organization.name,
      remote_created_at: new Date().getTime(),
      company_id: organization.id,
      non_profit_501_c_3: organization.nonprofit_501_c_3,
      additional_services: organization.additional_services,
      city: organization.primary_address.locality,
      country: organization.primary_address.subdivision_code,
      address_1: organization.primary_address.line_1,
      address_2: organization.primary_address.line_2,
    };
    global.analytics.identify(userId, {
      company: attributes,
    });
    global.analytics.track('Organization Created', {
      userId,
      company: attributes,
    });
  },

  /* The dashboard makes three analytics calls on page load and it is unclear
   * why. These are here to maintain that behavior, all the motifaction is a bit
   * unclear.*/
  trackDashboardForUnknownReasons(
    userId: string | null,
    orgName: string | void,
    subscriberStatus: 'Customer' | 'Trial'
  ) {
    global.analytics.page('Dashboard Page');
    global.analytics.track('Dashboard', {});
    global.analytics.identify(userId || 'unknown', {
      orgName,
      subsriber_status: subscriberStatus,
    });
  },

  // Called after user logs in
  trackUserLoggedIn(user: User, organizations: PersonOrganizationPermission[]) {
    const name = `${user.profile.first_name} ${user.profile.last_name}`;
    const dateRegistered = formatDate(user.date_registered);
    let userOrgNames = organizations.map((i) => i.organization.name);
    let userOrgRoles = organizations.map((i) => i.role);
    let userTime = new Date().toTimeString();

    global.analytics.alias(user.id);

    global.analytics.identify(user.id, {
      user_id: user.id,
      email: user.profile.email,
      first_name: user.profile.first_name,
      last_name: user.profile.last_name,
      name,
      //  company sets a users associated orgs in their profile page, NOT the main company, that will be done on the backend
      company: userOrgNames.join(', '),
      job_title: userOrgRoles.join(', '),
      phone: user.profile.phone,
      signed_up: dateRegistered,
      joined_pawlytics_date: dateRegistered,
      registered_at: dateRegistered,
      address: user.profile.address?.line_1,
      city: user.profile.address?.locality,
      state: user.profile.address?.subdivision_code,
      country: user.profile.address?.country_code,
      postal_code: user.profile.address?.postal_code,
      user_agent: window.navigator.userAgent,
      user_time: userTime,
      last_login_date: userTime,
    });

    global.analytics.track('Logged In', {
      user_id: user.id,
      email: user.profile.email,
      first_name: user.profile.first_name,
      last_name: user.profile.last_name,
      name,
      company: userOrgNames.join(', '),
      job_title: userOrgRoles.join(', '),
      phone: user.profile.phone,
      signed_up: dateRegistered,
      joined_pawlytics_date: dateRegistered,
      registered_at: dateRegistered,
      address: user.profile.address?.line_1,
      city: user.profile.address?.locality,
      state: user.profile.address?.subdivision_code,
      country: user.profile.address?.country_code,
      postal_code: user.profile.address?.postal_code,
      user_agent: window.navigator.userAgent,
      user_time: userTime,
    });
  },

  // Called after user signs up
  trackUserSignedUp(
    id: string,
    form: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      // emailLocked: boolean;
      accept: boolean;
    }
  ) {
    global.analytics.alias(id);
    global.analytics.identify(id, {
      first_name: form.firstName,
      last_name: form.lastName,
      email: form.email,
      phone: form.phone,
      invited: undefined, // form.emailLocked?.toString(),
    });
    global.analytics.track('Signed Up', {
      first_name: form.firstName,
      last_name: form.lastName,
      email: form.email,
      phone: form.phone,
      invited: undefined, //form.emailLocked?.toString(),
    });
  },

  petAdded(
    status: PetStatus,
    pet: PetEventIntakeResponse['pet_event_intake']['pet']
  ) {
    global.analytics.track('Added Pet', {
      species: pet.species || '',
      intakeType: pet.events ? pet.events[0].intake_type : '',
      status: status || '',
    });
  },

  petDeleted(petId: string, organizationId: string) {
    global.analytics.track('Added Pet', {
      pet_id: petId,
      organization_id: organizationId,
    });
  },

  trackUIEvent(event: string, opts?: any) {
    global.analytics.track(event, opts);
  },
};
