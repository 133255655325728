/**
 * The <Anchor /> component is a standard designed anchor using the brand
 * colors. This exists here primarily because at the time of creation we still
 * had Bootstrap and use this to override Bootstrap styles.
 *
 * Expected usage:
 *
 * ```
 *   <Anchor as={Link} href={`/pet/${pet.id}`}>{pet.name}</Anchor>
 * ```
 **/
import styled from 'styled-components';
import { blueLight, blue, coral, coralDark } from '../theme/colors';

export const Anchor = styled.a<{
  disabled?: boolean;
  $alternative?: boolean;
  as?: any;
}>`
  color: ${({ $alternative }) => ($alternative ? coral : blueLight)};
  transition: color 0.2s;

  &:hover,
  &:focus,
  &:focus-visible {
    color: ${({ $alternative }) => ($alternative ? coralDark : blue)};
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    color: inherit;
  }
`;
